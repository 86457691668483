<template>
  <b-row>
    <b-col sm="12">
      <b-table
          :items="taskList"
          :fields="fields"
          stacked="md"
          show-empty
          small
          headVariant="light"
          bordered
          responsive
          :busy="isLoading"
          :tbody-tr-class="getrowClassStatus"
      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>
        <template #empty="">
          <div role="alert" aria-live="polite"><div class="text-center my-2">Список заданий на выкуп пуст.</div></div>
        </template>
        <template #cell(date)="{item}">
          {{formatDate(item.createdAt)}} / {{formatDate(item.updatedAt)}}<br>
          <a href="#" @click.prevent="delTask(item)" class="text-danger">удалить</a>
        </template>
        <template #cell(user)="{item}">
<delivery-user-col :phone="item.user_phone"></delivery-user-col>
        </template>
        <template #cell(price)="{item}">
         цена при создании: {{item.price}}<br>
          цена выкупа: {{item.finalPrice}}
        </template>
        <template #cell(status)="{item}">
          {{item.status}}<br>

          <template v-if="item.status=='Ждёт оплаты'">
            <button size="sm" class="btn btn-sm btn-success" @click="item.status='Ждёт покупки';updTask(item)">оплатить</button>
          </template>
          <template v-else-if="item.status=='Ждёт покупки'">
            <button size="sm" class="btn btn-sm btn-outline-primary" disabled>напоминание</button>
          </template>
          <template v-else-if="item.status=='Доставляется'">
            <button size="sm" class="btn btn-sm btn-outline-primary" disabled>напоминание</button>
          </template>
          <template v-else-if="item.status=='Ждёт фото'">
            <button size="sm" class="btn btn-sm btn-outline-primary" disabled>напоминание</button>
          </template>
          <template v-else-if="item.status=='Ждёт написание отзыва'">
            <button size="sm" class="btn btn-sm btn-outline-primary" disabled>напоминание</button>
          </template>
          <template v-else-if="item.status=='Завершён'">

          </template>


        </template>


        <template #cell(action)="row">
          <b-button variant="outline-primary" size="sm" style="font-size: 18px" @click="row.toggleDetails">
            <b-icon-list-ol style="width: 15px; height: 15px"/>
            {{ row.detailsShowing ? 'Свернуть' : 'Подробнее' }}
          </b-button>
        </template>

        <template #row-details="row">
            <div class="col-6">
              <span v-for="ph in row.item.photo" :key="ph.tg_file_id">
            <a :href="ph.link" target="_blank"><img :src="ph.link" alt="" style="width: 70px"></a>
          </span>
              <b-form-group description="Рейтинг">
                <b-form-input size="sm" v-model="row.item.review.rating" placeholder="кол-во звезд"></b-form-input>
                <b-form-textarea rows="3" max-rows="6" placeholder="Текст отзыва" v-model="row.item.review.text">
                </b-form-textarea>
                <button size="sm" class="btn btn-sm btn-success" @click="updTask(row.item)">обновить</button>
              </b-form-group>
            </div>

        </template>

      </b-table>
    </b-col>
    <b-col sm="12">
      <b>Новый:</b> новый, <b>Ждёт оплаты:</b> надо скинуть денег,
      <b>Ждёт покупки:</b> ждёт выкупа с марктетплейса,
      <b>Доставляется:</b> в доставке на пвз,
      <b>Ждёт фото:</b> ждёт фото подтверждения товара,
      <b>Ждёт написание отзыва:</b> ждёт пока напишет отзыв,
      <b>Завершён:</b>
    </b-col>

    <b-col sm="12" lg="6" class="mt-3">
      <h6>Добавить выкуп</h6>
      <wbviews-add-new-order @addNewTask="addNewTask($event)" />
    </b-col>

  </b-row>
</template>

<script>

import DeliveryUserCol from "@/WbViews/Delivery/components/UserCol.vue";
import WbviewsAddNewOrder from "@/WbViews/Orders/AddForm.vue";
export default {
  name: "wbviews-delivery-list",
  props: [],
  data: () => ({
    show: false,
    isLoading: true,
    selected: [],
    value: [],
    taskList: [],
    fields: [
      { key: 'date', label: 'Создан / Обновлен', sortable: true, sortDirection: 'desc' },
      { key: 'user', label: 'Кабинет', sortable: true, sortDirection: 'desc' },
      { key: 'status', label: 'Статус', sortable: true, sortDirection: 'desc' },
      { key: 'price', label: 'Цена', sortable: true, sortDirection: 'desc' },
      { key: 'serach_text', label: 'Поисковая фраза', sortable: true, sortDirection: 'desc' },
      { key: 'lens', label: 'Товар к выкупу', sortable: false, sortDirection: 'desc' },
      // { key: 'review', label: 'Отзыв', sortable: false, sortDirection: 'desc' },
      // { key: 'finalPrice', label: 'Стоимость', sortable: false, sortDirection: 'desc' },
      { key: 'action', label: 'Действия', sortable: false, sortDirection: 'desc' },
    ]
  }),
  methods: {
    getrowClassStatus(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 'await courier') return 'table-success'

    },
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    formatDate(date) {
      // const ld = new Date(date)
      const fd = date.split("T")[0].split("-")
      const ft = date.split("T")[1].split(":")
      return `${fd[2]}.${fd[1]} ${+ft[0]+3}:${ft[1]} `
    },
    getOrders() {

      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-tasks`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.taskList = resp.items
              this.isLoading=false
            }
            if (!resp.status) this.alertMsg('Ошибка загрузки выкупов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    delTask(task) {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-task`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(task)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Удаление выкупа | Успешно', `удален с id: ${resp.message}`, 'success')
              this.taskList=this.taskList.filter(e=>e._id!=task._id)
            }
            if (!resp.status) this.alertMsg('Ошибка удаления выкупа', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    updTask(task) {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-tasks`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(task)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Обновлен | Успешно', `обновлен с id: ${resp.message}`, 'success')
              // this.taskList.find(e=>e._id==order._id).status='await review'
              // this.taskList.find(e=>e._id==order._id).buyInfo='Получен'
            }
            if (!resp.status) this.alertMsg('Ошибка обновления', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    addNewTask(newtask) {
      console.log('need upd',newtask)
      this.taskList.push(newtask)
    }
  },
  components: {WbviewsAddNewOrder, DeliveryUserCol },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getOrders()
    }

  }
};
</script>

<style scoped>
.btn-sm, .btn-group-sm > .btn {
  padding: 0rem 0.35rem!important;
}
div {
  font-size: 10px;
}
</style>