<template>
  <b-card bg-variant="light" body-class="p-2" footer-class="p-2">
    <b-form-group description="" :state="false">
      Линзы
      <b-form-input v-model="newTask.lens.art" @change="getWbInfo(newTask.lens.art)" size="sm" placeholder="артикул"></b-form-input>
      <b-form-input v-model="newTask.lens.name" size="sm" placeholder="название">-</b-form-input>
<!--      <b-form-input v-model="newTask.lens.market" size="sm" placeholder="маркетплейс"></b-form-input>-->
<!--      <b-form-input v-model="newTask.lens.barcode" size="sm" placeholder="шк"></b-form-input>-->
      <b-form-input size="sm" v-model="newTask.img.src" placeholder="Ссылка на фото"></b-form-input>
      <b-form-input size="sm" v-model="newTask.seller.name" placeholder="Название продавца" ></b-form-input>


<!--            <b-form-tags-->
<!--          input-id="tags-separators"-->
<!--          v-model="newTask.arts_wb"-->
<!--          separator=" ,;"-->
<!--          placeholder=""-->
<!--          size="md"-->
<!--          no-outer-focus-->
<!--      >-->
<!--        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">-->
<!--          <b-input-group aria-controls="my-custom-tags-list">-->
<!--            <input-->
<!--                v-bind="inputAttrs"-->
<!--                v-on="inputHandlers"-->
<!--                placeholder="Добавьте артикулы вб, через запятую или пробел"-->
<!--                class="form-control">-->
<!--            <b-input-group-append>-->
<!--              <b-button @click="addTag()" variant="primary" size="sm">добавить</b-button>-->
<!--            </b-input-group-append>-->
<!--          </b-input-group>-->
<!--          <ul-->
<!--              id="my-custom-tags-list"-->
<!--              class="list-unstyled d-inline-flex flex-wrap mb-0"-->
<!--              aria-live="polite"-->
<!--              aria-atomic="false"-->
<!--              aria-relevant="additions removals"-->
<!--          >-->
<!--            <b-card-->
<!--                v-for="tag in tags"-->
<!--                :key="tag"-->
<!--                :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"-->
<!--                tag="li"-->
<!--                class="mt-1 mr-1"-->
<!--                body-class="py-1 pr-2 pl-2 text-nowrap"-->
<!--            >-->
<!--              <a :href="`https://www.wildberries.ru/catalog/${tag}/detail.aspx?targetUrl=RG`" target="_blank" style="font-size: 12px">{{tag}}</a>-->
<!--              <b-button @click="removeTag(tag)" variant="outline-danger" size="sm" class="ml-1"><b-icon icon="trash" style="width: 15px; height: 15px;margin: 3px 0px 0 0"></b-icon></b-button>-->
<!--            </b-card>-->
<!--          </ul>-->
<!--        </template>-->
<!--      </b-form-tags>-->
    </b-form-group>
    <b-form-group description="">
      Как искать и за сколько покупать
      <b-form-input size="sm" v-model="newTask.serach_text" placeholder="поисковый запрос"></b-form-input>
      <b-form-input size="sm" v-model="newTask.lens.qty" placeholder="кол-во" type="number"></b-form-input>
      <b-form-input size="sm" v-model="newTask.price" placeholder="цена"></b-form-input>
    </b-form-group>
    <b-form-group description="Рейтинг">
      <b-form-input size="sm" v-model="newTask.review.rating" placeholder="кол-во звезд"></b-form-input>
      <b-form-textarea rows="3" max-rows="6" placeholder="Текст отзыва" v-model="newTask.review.text">
      </b-form-textarea>
    </b-form-group>
    <b-form-group description="Стратегия выкупа">
      <b-form-radio-group
          id="btn-radios-1"
          v-model="newTask.strategy"
          :options="[
          { text: 'Выкуп из поиска по фразе', value: 'artAndSearch', checked: true },
          { text: 'Выкуп из поиска по фильтрам', value: 'filterAndSearch' }]"
          button-variant="outline-primary"
          size="sm"
          name="radios-btn-default"
          buttons
      >
      </b-form-radio-group>
    </b-form-group>

    <b-row>
      <b-col lg="6">
        <b-form-group description="Список свободных аккаунтов">
          <b-form-select :options="accounts" v-model="newTask.user_phone" :select-size="4" ></b-form-select>
        </b-form-group>
      </b-col>

    </b-row>
    <b-button variant="success" size="md" block @click="addNewTask()" :disabled="isLoading"><b-spinner small v-if="isLoading"></b-spinner>Добавить в очередь</b-button>
<!--<pre>-->
<!--  {{newTask}}-->
<!--</pre>-->
  </b-card>
</template>

<script>

export default {
  name: "wbviews-add-new-order",
  props: [],
  data: () => ({
    isLoading: true,
    isLoadingBtn: false,
    newTask: {
      strategy:"artAndSearch",
      user_phone: "",
      serach_text: "",
      lens: {art:"", name:"", market:"", barcode:"", qty:1},//
      img: {src:""},
      seller: {name:""} ,
      price: null,
      status:'Новый',
      photo: [],
      review:{rating:"", text:""},
    },
    filterOption: "",
    couriers: [],
    accounts: [],
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getAccounts() {
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-users-free`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
      .then(resp => resp.json())
      .then(resp => {
        this.isLoading=false
        if (resp.status) {
          this.accounts=resp.items.map((e,indx)=>{
            return {text: `${indx} - ${e.name} | ${e._id}`, value: e.phone, disabled: e.disabled}
          })
        }
        if (!resp.status) this.alertMsg('Ошибка загрузки аккаунтов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
      })
    },
    getWbInfo(art_wb) {
      if(!art_wb) return
      this.isLoading=true
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/wb/${art_wb}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'dildo-token': this.$cookies.get("dildo-token")
        }
      })
      .then(resp => resp.json())
      .then(resp => {
        this.isLoading=false
        if (resp.status) {
          this.newTask.lens.name=resp.message.name
          this.newTask.lens.market='wb'
          this.newTask.lens.barcode='||||'
          this.newTask.lens.qty=1
          this.newTask.img.src=resp.message.market
          this.newTask.seller.name=resp.message.supplier
          this.newTask.price=resp.message.sizes[0].price.total/100
        }
        if (!resp.status) this.alertMsg('Ошибка загрузки аккаунтов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
      })
    },
    async addNewTask() {
      this.isLoadingBtn=true
      if (!this.validation) {
        console.log(this.validation)
        this.alertMsg('Добвляем выкуп | Ошибка', `Нужно выбрать артикулы, стратегию и курьера`, 'danger')
        this.isLoadingBtn=false
        return false
      }
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-tasks`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(this.newTask)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Добвляем выкуп | Успешно', `Задание добавлено в очередь. id:${resp.newtask._id}`, 'success')
              this.$emit('addNewTask', resp.newtask)
            }
            if (!resp.status) this.alertMsg('Добвляем выкуп | Ошибка', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          this.isLoadingBtn=false
          })
      .catch(err => {
        this.alertMsg('Добвляем выкуп | Ошибка 2', `Ошибка сервера, попробуйте ещё раз ${err}`, 'danger')
        this.isLoadingBtn=false
      })
    }
  },
  watch: {
    // 'newTask.lens.art': function (art) {
    //   console.log('add art',art)
    // }
  },
  computed: {
    validation() {
      if (!this.newTask.user_phone) return false
      if (!this.newTask.lens.art) return false
      if (!this.newTask.lens.name) return false
      if (!this.newTask.lens.qty) return false
      if (!this.newTask.serach_text) return false
return true
    }
  },
  components: { },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getAccounts()

    }

  }
};
</script>

<style>
.btn-sm, .btn-group-sm > .btn {
  padding: 0rem 0.35rem!important;
}
option {
  font-size: 10px;
}
hr {
  margin: 15px 0;
}
</style>