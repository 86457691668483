<template>
  <b-container fluid>
    <b-row>
      <b-nav>
        <b-nav-item active href="/wbviews/">Выкупы</b-nav-item>
        <b-nav-item href="/wbviews/users">Кабинеты</b-nav-item>
        <b-nav-item href="/wbviews/cards">Карты</b-nav-item>
        <b-nav-item href="/wbviews/couriers">Курьеры</b-nav-item>
      </b-nav>
    </b-row>
    <hr>
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col sm="12">
            <h6>Текущие выкупы</h6>
            <wbviews-delivery-list />
          </b-col>

        </b-row>
      </b-col>

    </b-row>

  </b-container>
</template>
<style>
td {
  font-size: 10px;
}
th div {
  font-size: 10px;
}
.text-muted {
  color: #6c757d6e !important;
}
</style>
<script>

import WbviewsDeliveryList from "@/WbViews/Delivery/DeliveryList";

export default {
  name: "wbviews-home",
  data: () => ({
    isLoading: true,
  }),
  methods: {},
  components: {WbviewsDeliveryList},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    // if (dildoToken) {
    //
    // }
  }
};
</script>


