<template>
  <b-row>
    <b-col class="pr-0">
      <template v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
      </template>
      <template v-else>
<!--        [  "PVZ": { "address": "Люберцы, вертолетная 6", "id": null }, "_id": "65fc19c83757506429c4f6d4", "status": false, "phone": "9991403228", "name": "Пётр Медведев", "comment": "из тг", "sex": "муж", "city": "Москва", "bank": [ { "bankName": "озон банк", "cardNumber": "сбп", "_id": "65fc19c83757506429c4f6d5" } ], "courier": "self", "lens": [ { "art": "", "name": "Oasys 1-day -4.5", "market": "", "barcode": "", "_id": "65fc19c83757506429c4f6d6" } ], "myacuvue": "", "__v": 0, "createdAt": "2024-03-21T11:28:08.237Z", "updatedAt": "2024-03-26T09:43:12.554Z" } ]-->

        {{userData.name}} {{userData.phone}} <br>
        {{userData.city}} {{userData.PVZ.address}}<br>
        {{userData.bank.bankName}}

      </template>
    </b-col>
  </b-row>

</template>

<style>
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>

<script>
// import _ from "lodash"
// import dayjs from 'dayjs'
// import weekOfYear from "./node_modules/dayjs/plugin/weekOfYear.js"
// import './node_modules/dayjs/locale/ru.js'

export default {
  name: "delivery-user-col",
  props: ['phone'],
  data() {
    return {
      isLoading: true,
      userData:{}

    };
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getUserData()
  },
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getUserData() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/self-user-phone/${this.phone}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            console.log(`get user ${this.phone}`)
            console.log(resp)
            this.isLoading=false
            if (resp.status) {
              this.userData =  resp.item
            }
            if (!resp.status) {
              this.userData ={}
              this.alertMsg('Ошибка загрузки данных полльзователя', `Ошибка сервера, попробуйте ещё раз ${resp.message.code} ${resp.message.desc} ${this.phone}`, 'danger')
            }
          })
    }

  },
  computed: {
    // dohod: function () {
    //   return Math.ceil(this.sp - this.zakup - this.sp/100*this.komsawb - this.upak)
    // }
  },
};
</script>